import * as React from "react"
import { browserCompat } from "../utils"
import { navigate } from "gatsby"

export const PasswordGuardContext = React.createContext({
  isVerified: false,
  setIsVeryfied: () => {},
})
export const USER_VALIDATED = "USER_VALIDATED"

export default function PasswordGuardProvider({ children }) {
  const [isVerified, setIsVeryfied] = React.useState(
    browserCompat(() => sessionStorage.getItem(USER_VALIDATED)) || false
  )

  if (!isVerified) navigate("/login")

  return (
    <PasswordGuardContext.Provider value={{ isVerified, setIsVeryfied }}>
      {children}
    </PasswordGuardContext.Provider>
  )
}

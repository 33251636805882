import * as React from "react"
import { Helmet } from "react-helmet"
import "typeface-poppins"
import PasswordGuardProvider from "./src/components/password-guard.context"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
      </Helmet>
      <PasswordGuardProvider>{element}</PasswordGuardProvider>
    </>
  )
}
